import {
  myRentalsShallowMount,
  testMyRentalsShallowMount,
} from "@/tests/unit/setup";
import ToolsList from "@/components/ToolsList.vue";
import { ICard } from "@/models/Card";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";

jest.mock("@/services/MyRentalsConfig");

const defaultProps = {
  customTools: [
    {
      to: MYHExternalRoutes.simulatorTool(),
      tracking: "link_tools_simulator",
      qa: "toolButton-simulateMortgage",
      text: "common.simulateMortgagecustom",
    },
    {
      to: MYHExternalRoutes.valuationTool(),
      tracking: "link_tools_valuation",
      qa: "toolButton-valuateAProperty",
      text: "common.valuateAPropertycustom",
    },
  ],
};

const defaultData: (ICard | null)[] = [
  {
    to: MYHExternalRoutes.valuationTool(),
    tracking: "link_tools_valuation",
    qa: "toolButton-valuateAProperty",
    text: "common.valuateAProperty",
  },
  {
    to: MYHExternalRoutes.simulatorTool(),
    tracking: "link_tools_simulator",
    qa: "toolButton-simulateMortgage",
    text: "common.simulateMortgage",
  },
  null,
  null,
];

const component = (data = {}, props = {}) => {
  return myRentalsShallowMount(ToolsList, {
    data,
    props,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("ToolsList", () => {
  testMyRentalsShallowMount(ToolsList);

  describe("Computed", () => {
    describe("toolsList", () => {
      it("Should return customTools", () => {
        // given
        const list = component({}, defaultProps);
        // when
        // then
        expect(list.vm.toolsList).toEqual(defaultProps.customTools);
      });

      it("Should return default tools", () => {
        // given
        const list = component();
        // when
        // then
        expect(list.vm.toolsList).toEqual(defaultData);
      });
    });
  });
});
